export default {
    dashs: [
        {
            sector: 'campaigns',
            title: 'Campanhas',
            count: 4,
            icon: 'mdi-vote-outline',
            path: 'campaigns_company'
        }
    ]
};
